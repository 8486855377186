




















































































import Vue from 'vue'
// import HelloWorld from './components/HelloWorld.vue'

export default Vue.extend({
  name: 'App',

  components: {
  },

  data: () => ({
    links: [
      '/',
      'About Us',
      'Team',
      'Services',
      'Blog',
      'Contact Us'
    ]
  })
})
